import React from 'react';
import Box from '@mui/material/Box';
import Error from './Error';

const ErrorTemporaryOffline: React.FunctionComponent = () => {
    return  <Box sx={{ padding: '0 20px' }}>
        <Error showRefresh code="temporary-offline" />
    </Box>;
};

export default ErrorTemporaryOffline;
