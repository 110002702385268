import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ProfileFilledIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 26 26'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M4.9375 20.0312C6.25 19.1979 7.54688 18.5677 8.82812 18.1406C10.1094 17.7135 11.5 17.5 13 17.5C14.5 17.5 15.8958 17.7135 17.1875 18.1406C18.4792 18.5677 19.7812 19.1979 21.0938 20.0312C22.0104 18.9062 22.6615 17.7708 23.0469 16.625C23.4323 15.4792 23.625 14.2708 23.625 13C23.625 9.97917 22.6094 7.45312 20.5781 5.42188C18.5469 3.39062 16.0208 2.375 13 2.375C9.97917 2.375 7.45312 3.39062 5.42188 5.42188C3.39062 7.45312 2.375 9.97917 2.375 13C2.375 14.2708 2.57292 15.4792 2.96875 16.625C3.36458 17.7708 4.02083 18.9062 4.9375 20.0312ZM13 13.9375C11.7917 13.9375 10.776 13.526 9.95312 12.7031C9.13021 11.8802 8.71875 10.8646 8.71875 9.65625C8.71875 8.44792 9.13021 7.43229 9.95312 6.60938C10.776 5.78646 11.7917 5.375 13 5.375C14.2083 5.375 15.224 5.78646 16.0469 6.60938C16.8698 7.43229 17.2812 8.44792 17.2812 9.65625C17.2812 10.8646 16.8698 11.8802 16.0469 12.7031C15.224 13.526 14.2083 13.9375 13 13.9375ZM13 25.5C11.25 25.5 9.61458 25.1719 8.09375 24.5156C6.57292 23.8594 5.25 22.9635 4.125 21.8281C3 20.6927 2.11458 19.3646 1.46875 17.8438C0.822917 16.3229 0.5 14.6979 0.5 12.9688C0.5 11.2604 0.828125 9.64583 1.48437 8.125C2.14062 6.60417 3.03646 5.28125 4.17188 4.15625C5.30729 3.03125 6.63542 2.14062 8.15625 1.48437C9.67708 0.828125 11.3021 0.5 13.0312 0.5C14.7396 0.5 16.3542 0.828125 17.875 1.48437C19.3958 2.14062 20.7188 3.03125 21.8438 4.15625C22.9688 5.28125 23.8594 6.60417 24.5156 8.125C25.1719 9.64583 25.5 11.2708 25.5 13C25.5 14.7083 25.1719 16.3229 24.5156 17.8438C23.8594 19.3646 22.9688 20.6927 21.8438 21.8281C20.7188 22.9635 19.3958 23.8594 17.875 24.5156C16.3542 25.1719 14.7292 25.5 13 25.5Z'
                }
                fill={ props.fill || theme.palette.text.primary}
            />
        </SvgIcon>
    );
}

export default ProfileFilledIcon;
