import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const styles = {
    headerBottomContainer: (theme: Theme) => ({
        backgroundColor: theme.palette.primary.main,
    }),
    headerBottom: {
        padding: '30px 20px 0',
    },
    title: (theme: Theme) => ({
        fontSize: '44px',
        fontWeight: 800,
        lineHeight: '54px',
        color: theme.palette.common.white,
    }),
    subtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '17px',
        paddingBottom: '26px',
    },
    contentContainer: {
        padding: '20px 20px 46px',
    },
};

export interface ProfileMobileLayoutProps {
    title: string;
    subtitle: JSX.Element;
    content: JSX.Element;
}

const ProfileMobileLayout: FunctionComponent<
    ProfileMobileLayoutProps
> = props => {
    const { title, subtitle, content } = props;

    return <React.Fragment>
        <Box sx={ styles.headerBottomContainer }>
            <Box sx={ styles.headerBottom }>
                <Typography sx={ styles.title }>
                    { title }
                </Typography>
                <Box sx={ styles.subtitleContainer }>
                    { subtitle }
                </Box>
            </Box>
        </Box>
        <Box sx={ styles.contentContainer }>
            { content }
        </Box>
    </React.Fragment>;
};

export default ProfileMobileLayout;
