import React, { FunctionComponent, useEffect, useState } from 'react';
import PageLayout from '../../../layouts/PageLayout';
import ProfileTitleContent from './ProfileTitleContent';
import ProfileFilledIcon from '../../../assets/SvgIcons/ProfileFilledIcon';
import { Theme, useTheme } from '@mui/material/styles';
import ProfileDesktopLayout from './ProfileDesktopLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyValueProfileContent from './KeyValueProfileContent';
import HistoryIcon from '../../../assets/SvgIcons/HistoryIcon';
import InfoVerification from './InfoVerification';
import Typography from '@mui/material/Typography';
import { ApiClient, isLight, RestMethod } from '../../../common/helpers';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    KnownBreakpoints,
    THEME_BREAKPOINTS,
} from '../../../common/constants';
import ProfileMobileLayout from './ProfileMobileLayout';
import PrizeIcon from '../../../assets/SvgIcons/PrizeIcon';
import { LoyaltyMobileProps } from '../../Loyalty/LoyaltyMobile';
import useAuthorized from '../../../common/hooks/useAuthorized';
import { useRouter } from 'next/router';
import PageLoader from '../PageLoader';
import { formatDateTime, formatScalar } from '../../../common/helpers/format';
import { STATES } from '../../../config/membersArea';
import DialogAlert from '../DialogAlert';
import RefreshIcon from '../../../assets/SvgIcons/RefreshIcon';
import { payFrequencyMap } from '../../../common/helpers/payFrequencyMap';
import asyncLogger from '../../../common/logger';
import { useAccountInfo } from '../../../common/hooks/AccountInfoProvider';
import SmartLink from '../../SmartLink';
import { BackToAccountLink } from '../../Form/Links';
import { Customer } from '../../../common/types/Customer';
import { AddressType, PhoneType } from '../../../common/types/enums';
import {
    biometricLoginAllowed,
    isBiometricLoginEnabled,
} from '../../../common/helpers/biometricLogin';
import ErrorTemporaryOffline from '../../ErrorTemporaryOffline';

const HEADER_OFFSET = 247;

const styles = {
    container: {
        paddingTop: '33px',
    },
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '390px',
        minWidth: '290px',
        paddingRight: '105px',
        position: 'sticky',
        top: '236px',
        height: '100%',
    },
    button: (theme: Theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        color: theme.palette.text.primary,
        lineHeight: '30px',
        marginBottom: '10px',
        boxShadow: 'unset',
        borderRadius: '10px',
        height: '30px',
        placeContent: 'start',
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.action.active
                : theme.palette.secondary.main
            ,
            color: theme.palette.text.primary,
            boxShadow: 'none',
        },
    }),
    clickedButton: (theme: Theme) => ({
        fontWeight: 700,
        fontSize: theme.typography.fontSize,
        color: theme.palette.text.primary,
        lineHeight: '30px',
        marginBottom: '10px',
        boxShadow: 'unset',
        borderRadius: '10px',
        height: '30px',
        placeContent: 'start',
        backgroundColor: isLight(theme)
            ? theme.palette.action.active
            : theme.palette.secondary.main
        ,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.action.active
                : theme.palette.secondary.main
            ,
            color: theme.palette.text.primary,
            boxShadow: 'none',
        },
    }),
    contentContainer: {
        paddingTop: '8px',
    },
    linkContainer: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        marginBottom: '55px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginBottom: '-20px',
            paddingTop: '10px',
        },
    }),
    link: (theme: Theme, isHomeLink?: boolean) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16.41px',
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        marginBottom: isHomeLink ? '20px' : 'unset',
        '&:hover': {
            textDecoration: 'unset',
        },
    }),
    icon: {
        fontSize: '17px',
        marginLeft: '14px',
    },
    subtitle: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '32px',
        color: theme.palette.common.white,
        paddingLeft: '8px',
    }),
    subtitleIcon: {
        width: '21px',
        height: '19px',
    },
    dialogIcon: (theme: Theme) => ({
        fontSize: '81px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            fontSize: '76px',
        },
    }),
};

interface InfoComponent {
    id: string;
    title: string;
    editable: boolean;
}

interface ContentProps {
    profileData: { [key: string]: any };
    reload?: () => void;
    openAlert?: () => void;
    openRequestAlert?: () => void;
}

const INFO_COMPONENTS: InfoComponent[] = [{
    id: 'accountInformation',
    title: 'Account information',
    editable: false,
}, {
    id: 'personalInformation',
    title: 'Personal information',
    editable: true,
}, {
    id: 'employmentInformation',
    title: 'Work information',
    editable: true,
}, {
    id: 'bankAccountInformation',
    title: 'Bank account',
    editable: true,
}, {
    id: 'securityInformation',
    title: 'Security',
    editable: true,
}, {
    id: 'topicsInformation',
    title: 'Message Subscriptions',
    editable: true,
}];

const DesktopSidebar: FunctionComponent = () => {
    const [clicked, setClicked] = useState(INFO_COMPONENTS[0].id);

    function scrollToTitle(id: string): void {
        const element = document.getElementById(id);

        if (!element) {
            return ;
        }

        element.scrollIntoView({ behavior: 'instant' });
    }

    return <Box sx={ styles.sidebar }>
        <BackToAccountLink/>
        { INFO_COMPONENTS.map((button, index) => (
            <Button
                key={ index }
                sx={ clicked !== button.id
                    ? styles.button
                    : styles.clickedButton
                }
                onClick={ () => {
                    setClicked(button.id);
                    scrollToTitle(button.id + 'Shadow');
                }}
            >
                { button.title }
            </Button>
        ))}
    </Box>;
};

const LoanHistoryLink: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.linkContainer }>
        <SmartLink
            href={ NamedRoute.LOAN_HISTORY }
            style={ (styles.link as any)(theme) }
        >
            See loan history
        </SmartLink>
        <HistoryIcon sx={ styles.icon }/>
    </Box>;
};

const DesktopContent: FunctionComponent<ContentProps> = props => {
    const { profileData, reload, openAlert, openRequestAlert } = props;

    return <Box sx={ styles.contentContainer }>
        { INFO_COMPONENTS.map(component => <KeyValueProfileContent
            key={ component.id }
            id={ component.id }
            content={ profileData[component.id] }
            title={ component.title }
            editable={ component.editable }
            shadowComponent={{
                id: component.id + 'Shadow',
                offsetY: HEADER_OFFSET,
            }}
            reload={ reload }
            openAlert={ openAlert }
            openRequestAlert={ openRequestAlert }
        />) }
        <LoanHistoryLink />
    </Box>;
};

const MobileContent: FunctionComponent<ContentProps> = props => {
    const { profileData, reload, openAlert, openRequestAlert } = props;

    return <React.Fragment>
        { INFO_COMPONENTS.map(component => <KeyValueProfileContent
            key={ component.id }
            id={ component.id }
            content={ profileData[component.id] }
            title={ component.title }
            editable={ component.editable }
            reload={ reload }
            openAlert={ openAlert }
            openRequestAlert={ openRequestAlert }
        />) }
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <LoanHistoryLink />
            <BackToAccountLink />
        </Box>
    </React.Fragment>;
};

const ProfileMobileSubtitle: FunctionComponent<LoyaltyMobileProps> = props => {
    const theme = useTheme<Theme>();
    const { status, points } = props;

    return <React.Fragment>
        <PrizeIcon
            sx={ styles.subtitleIcon }
            fill={ theme.palette.common.white }
        />
        <Typography component="h4" sx={ styles.subtitle }>
            { `${ status } status / ${ points } points` }
        </Typography>
    </React.Fragment>;
};

const ProfilePage: FunctionComponent = () => {
    const router = useRouter();
    const authorized = useAuthorized();
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const [loading, setLoading] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [openRequestAlert, setOpenRequestAlert] = useState(false);
    const [profileData, setProfileData] = useState<
        { [key: string]: any } | undefined
    >(undefined);
    const accountInfo = useAccountInfo();
    const accountInfoLoading = accountInfo.loading;
    const [networkError, setNetworkError] = useState(false);

    const element = <ProfileTitleContent
        title={ 'Profile information' }
        icon={ <ProfileFilledIcon
            fill={ theme.palette.common.white }
            sx={{ fontSize: '25px' }}
        /> }
    />;

    const handleAlertOpen = () => {
        setOpenAlert(true);
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    };

    function profileStateMap(state: string | null | undefined) {
        return formatScalar(state) !== '—'
            ? (STATES as { [key: string]: string} )[state as string]
            : '—'
        ;
    }

    function formatProfileValue(
        value: string | null | boolean | undefined,
        format?: any,
    ) {
        return formatScalar(value) !== '—'
            ? format
                ? format
                : value !== false
                    ? 'YES'
                    : 'NO'
            : '—'
        ;
    }

    function profileDataMap(
        inputCustomer: Customer,
        topics: {
            id: number,
            displayName: string,
            isSubscribed: boolean,
            isUnsubscribable: boolean,
            isChild: boolean,
        }[],
    ) {
        const homeAddress = inputCustomer.addresses?.find(
            i => i.type === AddressType.Home,
        );
        const primaryBankAccount = inputCustomer.bankAccounts?.find(
            i => i.primary,
        );
        const primaryEmployment = inputCustomer.employments?.find(
            i => i.primary,
        );
        const phoneCell = inputCustomer.phones?.find(
            i => i.type === PhoneType.Cell,
        );
        const contentMap: { [key: string]: any } = {
            'accountInformation': {
                'First name': formatScalar(inputCustomer.firstName),
                'Last name': formatScalar(inputCustomer.lastName),
                'Email': <InfoVerification
                    value={ formatScalar(inputCustomer.email) }
                    verified={ Boolean(inputCustomer.emailVerified) }
                    entity={ 'Email' }
                    customerId={ inputCustomer.id }
                    setNetworkError={ setNetworkError }
                    displayValue
                />,
                'Phone number': <InfoVerification
                    value={ formatScalar(phoneCell?.phoneNumber) }
                    verified={ Boolean(phoneCell?.verified) }
                    entity={ 'Phone' }
                    customerId={ inputCustomer.id }
                    setNetworkError={ setNetworkError }
                    displayValue
                />,
            },
            'personalInformation': {
                'Social security number': formatScalar(inputCustomer.ssn),
                'Date of birth': formatProfileValue(
                    inputCustomer.birthDate,
                    formatDateTime(inputCustomer.birthDate),
                ),
                'Street address': formatScalar(homeAddress?.street),
                'City': formatScalar(homeAddress?.city),
                'State': profileStateMap(homeAddress?.state),
                'Zip code': formatScalar(homeAddress?.zip),
                'Driver\'s license state': profileStateMap(
                    inputCustomer.driversLicense?.state,
                ),
                'Driver\'s license number': formatScalar(
                    inputCustomer.driversLicense?.id,
                ),
                'Currently serving in the U.S. Military': formatProfileValue(
                    inputCustomer.military,
                ),
            },
            'employmentInformation': {
                'Work place': formatScalar(primaryEmployment?.name),
                'Work address': formatScalar(primaryEmployment?.address?.street),
                'City': formatScalar(primaryEmployment?.address?.city),
                'State': profileStateMap(primaryEmployment?.address?.state),
                'Zip code': formatScalar(primaryEmployment?.address?.zip),
                'Work phone': formatScalar(
                    (primaryEmployment?.phones || [])[0]?.phoneNumber,
                ),
                'Monthly income': formatProfileValue(
                    primaryEmployment?.monthlyIncome as any,
                    '$' + primaryEmployment?.monthlyIncome,
                ),
                'Payment type': formatScalar(primaryEmployment?.incomeType),
                ...payFrequencyMap(primaryEmployment),
                'Additional income': formatProfileValue(false),
            },
            'bankAccountInformation': {
                'Bank name': formatScalar(primaryBankAccount?.bankName),
                'Routing number': formatScalar(
                    primaryBankAccount?.routingNumber,
                ),
                'Account number': formatScalar(
                    primaryBankAccount?.accountNumber,
                ),
                'Account type': formatScalar(primaryBankAccount?.type),
            },
            'securityInformation':  {
                ...(biometricLoginAllowed() ? {
                    'Biometric login': isBiometricLoginEnabled()
                        ? 'Activated'
                        : 'Disabled',
                } : {}),
                '2-factor verification': inputCustomer.mfa?.enabled
                    ? 'Activated'
                    : 'Disabled',
                '2-factor verification method': inputCustomer.mfa?.methods[0]
                    ? inputCustomer.mfa?.methods[0]
                    : '—',
            },
            'topicsInformation': topics.reduce((value, topic) =>
                ({
                    ...value,
                    [topic.displayName + ' Events']: {
                        value: topic.isSubscribed,
                        id: topic.id,
                        disabled: topic.isUnsubscribable,
                        withIndent: topic.isChild,
                        displayValue: topic.isSubscribed
                            ? 'Subscribed'
                            : 'Unsubscribed',
                    },
                }),
                {},
            ),
        };

        return contentMap;
    }

    async function load() {
        try {
            const customerId = accountInfo?.customer?.id;

            if (!customerId) {
                return;
            }

            const [
                { data: { customer } },
                { data: { notificationSubscriptions: subscriptions } },
            ] = await Promise.all([
                ApiClient.request(
                    '/customerInfo',
                    {
                        method: RestMethod.GET,
                        query: { customerId: String(customerId) },
                        withAuth: true,
                    },
                ),
                ApiClient.request(
                    '/getSubscriptions',
                    { method: RestMethod.GET, withAuth: true },
                ),
            ]);

            if (!subscriptions) {
                return;
            }

            const mappedProfileData = profileDataMap(customer, subscriptions);

            setProfileData(mappedProfileData);
        } catch (err) {
            asyncLogger.error(err);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (!authorized) {
            return ;
        }

        (async (): Promise<void> => {
            await load();
        })();
        // eslint-disable-next-line
    }, [accountInfoLoading]);

    if (loading) {
        return <PageLoader/>;
    }

    const reload = async () => {
        if (authorized) {
            setLoading(true);

            await load();
        }
    };

    return <PageLayout
        title="Profile"
        withActions={ false }
        headerBottomSticky={{ element, zIndex: 1000 }}
        networkError={ networkError }
        setNetworkError={ setNetworkError }
    >
        { profileData
            ? <React.Fragment>
                { isDesktop
                    ? <Box sx={ styles.container }>
                        <ProfileDesktopLayout
                            sidebar={ <DesktopSidebar/> }
                            content={ <DesktopContent
                                profileData={ profileData }
                                reload={ reload }
                                openAlert={ handleAlertOpen }
                                openRequestAlert={
                                    () => setOpenRequestAlert(true)
                                }
                            /> }
                        />
                    </Box>
                    : <ProfileMobileLayout
                        title="Profile"
                        subtitle={ <ProfileMobileSubtitle
                            status={
                                accountInfo?.customer?.loyalty?.status ||
                                'Silver' as any
                            }
                            points={ Number(
                                accountInfo?.customer?.loyalty?.points,
                            ) || 960 }
                        /> }
                        content={ <MobileContent
                            profileData={ profileData }
                            reload={ reload }
                            openAlert={ handleAlertOpen }
                            openRequestAlert={
                                () => setOpenRequestAlert(true)
                            }
                        /> }
                    />
                }
                <DialogAlert
                    icon={ <RefreshIcon
                        fill={ isDesktop
                            ? theme.palette.primary.main
                            : theme.palette.common.white
                        }
                        sx={ styles.dialogIcon }
                    /> }
                    title={ 'Your password was successfully changed!' }
                    subtitle={ 'You will be redirected to Home page' }
                    buttonTitle={ 'Got it!' }
                    buttonProps={{ onClick: () => router.push('/app') }}
                    dialogProps={{
                        open: openAlert,
                        onClose: handleAlertClose,
                    }}
                />
                <DialogAlert
                    icon={ <RefreshIcon
                        fill={ isDesktop
                            ? theme.palette.primary.main
                            : theme.palette.common.white
                        }
                        sx={ styles.dialogIcon }
                    /> }
                    title={ 'Our support team received the message with the '
                        + 'information that you would like to update. Your '
                        + 'request will be processed as soon as possible.'
                    }
                    subtitle={ 'You will be redirected to Home page' }
                    buttonTitle={ 'Got it!' }
                    buttonProps={{ onClick: () => router.push('/app') }}
                    dialogProps={{
                        open: openRequestAlert,
                        onClose: () => setOpenRequestAlert(false),
                    }}
                />
            </React.Fragment>
            : <Box sx={ isDesktop
                ? styles.container
                : { ...styles.container, paddingLeft: '20px' }
            }>
                <ErrorTemporaryOffline />
            </Box>
        }
    </PageLayout>;
};

export default ProfilePage;
